<script lang="ts" setup>
import { useGlobalStore } from '@/stores'
const globalStore = useGlobalStore()

const year = new Date().getFullYear()
</script>

<template>
  <div class="copyright" :class="{ dark: globalStore.isDark, ins: globalStore.isDark, white1: globalStore.isWhite1 }">
    <div class="wrap">
      <div class="copyright__left">
        <nuxt-link to="/sitemap" title="網站地圖">網站地圖</nuxt-link><br />
        <nuxt-link to="/privacy-policy" title="隱私權及網站使用條款">隱私權及網站使用條款</nuxt-link>
      </div>
      <div class="copyright__center">© {{ year }} 恆隆行 All rights reserved.</div>
      <div class="copyright__right">
        <nuxt-link to="/complaints-info" title="申訴信箱">申訴信箱</nuxt-link><br />
        <nuxt-link to="/membership-terms" title="會員權益聲明">會員權益聲明</nuxt-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.copyright {
  position: relative;
  background-color: $color-white-2;
  padding-top: toRem(35);
  padding-bottom: toRem(35);

  &.white1 {
    background-color: $color-white-1;
  }


  &.ins {
    &.dark {
      background-color: $color-black-1;
    }
  }

  @include max-media(640) {
    color: $color-gray-1;
  }

  a {
    font-size: toRem(14);
    color: $color-gray-1;
  }

  .wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    padding-left: toRem(25);
    padding-right: toRem(25);
    font-size: toRem(14);

    @include max-media(640) {
      flex-wrap: wrap;
    }
  }

  &__center {
    @include min-media(641) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    @include max-media(640) {
      width: 100%;
      order: 2;
      padding-top: toRem(8);
    }
  }

  &__right {
    text-align: right;
  }

  &.dark {
    color: $color-white-1;

    a {
      color: $color-white-1;
    }
  }

}
</style>
