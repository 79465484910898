<script lang="ts" setup>
import Link1 from '@/components/Button/Link1.vue'
import { useGlobalStore } from '@/stores'
import { usePageStore } from '@/stores'
const globalStore = useGlobalStore()
const pageStore = usePageStore()
const { footer } = pageStore

interface Menu {
  link: string
  title: string
}

const mediaLists: Ref<Menu[]> = ref([
  {
    link: '/hence',
    title: 'hence, 雜誌'
  },
  {
    link: '/hence/recipe',
    title: '恆食堂'
  },
  {
    link: '/blog',
    title: '品牌部落格'
  }
])

const storeLists: Ref<Menu[]> = ref([
  {
    link: 'https://shop.hengstyle.com/',
    title: '線上購物'
  },
  {
    link: '/locations',
    title: '百貨專櫃據點'
  }
])

const membershipLists: Ref<Menu[]> = ref([
  {
    link: 'https://page.line.me/wxq1916l?openQrModal=true',
    title: 'Line 隨行服務'
  },
  {
    link: '/events',
    title: '專屬活動體驗'
  },
  {
    link: '/services',
    title: '會員售後服務'
  },
  {
    link: '/membership',
    title: '生活有亮點計劃'
  }
])
</script>

<template>
  <footer class="footer" :class="{ dark: globalStore.isDark, white1: globalStore.isWhite1 }">
    <div class="wrap">
      <div class="footer__grid pc">
        <div class="footer__left">
          <div class="section">
            <h3 class="section__title">media</h3>
            <div class="section__list media">
              <nuxt-link v-for="(item, index) in mediaLists" :key="index" :to="item.link" :title="item.title">
                {{ item.title }}
              </nuxt-link>
            </div>
          </div>
          <div class="section">
            <h3 class="section__title">store</h3>
            <div class="section__list">
              <nuxt-link v-for="(item, index) in storeLists" :key="index" :to="item.link" :title="item.title">
                {{ item.title }}
              </nuxt-link>
            </div>
          </div>
          <Social class="footer__social footer__social-pc" />
        </div>
        <div class="footer__center">
          <Nimg :src="footer?.image?.link" :alt="footer?.image?.alt || '底部圖片'" format="webp" />
        </div>
        <div class="footer__right">
          <div class="section column">
            <h3 class="section__title">membership</h3>
            <div class="section__list">
              <nuxt-link v-for="(item, index) in membershipLists" :key="index" :to="item.link" :title="item.title">
                {{ item.title }}
              </nuxt-link>
            </div>
          </div>
          <Link1 to="https://hlh.tw/DLSjR" class="footer__right-link">聯絡我們</Link1>
        </div>
      </div>
      <div class="footer__grid mobile">
        <div class="footer__center">
          <Nimg :src="footer?.image?.link" :alt="footer?.image?.alt || '底部圖片'" format="webp" />
        </div>
        <div class="footer__bottom">
          <div class="section section1">
            <h3 class="section__title">media</h3>
            <div class="section__list media">
              <nuxt-link v-for="(item, index) in mediaLists" :key="index" :to="item.link" :title="item.title">
                {{ item.title }}
              </nuxt-link>
            </div>
          </div>
          <div class="section section2">
            <h3 class="section__title">membership</h3>
            <div class="section__list">
              <nuxt-link v-for="(item, index) in membershipLists" :key="index" :to="item.link" :title="item.title">
                {{ item.title }}
              </nuxt-link>
            </div>
          </div>
          <div class="section section3">
            <h3 class="section__title">store</h3>
            <div class="section__list">
              <nuxt-link v-for="(item, index) in storeLists" :key="index" :to="item.link" :title="item.title">
                {{ item.title }}
              </nuxt-link>
            </div>
          </div>
          <Link1 to="https://hlh.tw/DLSjR" class="footer__right-link section4">聯絡我們</Link1>
        </div>
      </div>

      <Social class="footer__social footer__social-mobile" />
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  padding-bottom: toRem(110);

  &.dark {
    color: $color-white-1;

    a {
      color: $color-white-1;
    }
  }

  @include max-media(640) {
    padding-bottom: toRem(0);
  }

  &.ins {
    position: relative;
    background-color: #f2f2f2;
    padding-top: toRem(100);

    &.white1 {
      background-color: $color-white-1;
    }

    &.dark {
      background-color: $color-black-1;
    }

    @include max-media(640) {
      padding-top: toRem(0);
      margin-top: toRem(55);
    }
  }

  .wrap {
    @include max-media(767) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__grid {
    @include grid;

    &.pc {
      @include max-media(767) {
        display: none;
      }
    }

    &.mobile {
      @include grid(4, 24);

      @include min-media(768) {
        display: none;
      }

      a {
        margin-bottom: toRem(5);
        // color: rgba($color-black-1, 0.75);
      }

      .footer {
        &__center {
          grid-column: 1 / span 4;
        }

        &__bottom {
          @include grid(4, 24);
          grid-column: 1 / span 4;
          padding-left: toRem(24);
          padding-right: toRem(24);
        }
      }

      .section1 {
        grid-column: 1 / span 2;
      }

      .section2 {
        grid-column: 3 / span 2;
        margin-bottom: toRem(45);

        .section {
          &__list {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .section3 {
        grid-column: 1 / span 2;
        margin-bottom: toRem(45);
      }

      .section4 {
        grid-column: 3 / span 2;
      }
    }

    // @include max-media(767) {
    //   grid-template-columns: repeat(4, 1fr);
    // }
  }

  &__left {
    grid-column: 3 / span 2;
    display: flex;
    flex-direction: column;

    @include max-media(960) {
      grid-column: 1 / span 3;
    }

    @include max-media(767) {
      grid-row: 2;
      grid-column: 1 / span 2;
      padding-left: toRem(30);
    }

    .section {
      margin-bottom: toRem(35);

      &__list {
        &.media {
          @include min-media(1367) {
            display: flex;
          }

          a {
            @include min-media(1367) {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  &__social {
    &-pc {
      margin-top: auto;

      @include max-media(640) {
        display: none !important;
      }
    }

    &-mobile {
      display: flex;
      justify-content: center;
      width: 100%;
      padding-top: toRem(50);
      padding-bottom: toRem(30);

      @include min-media(641) {
        display: none !important;
      }
    }
  }

  &__center {
    // width: 100%;
    // max-width: toRem(393);
    // margin-left: toRem(50);
    // margin-right: toRem(50);
    grid-column: 5 / span 4;
    padding-left: toRem(27);
    padding-right: toRem(27);

    @include max-media(960) {
      grid-column: 4 / span 6;
    }

    @include max-media(767) {
      grid-row: 1;
      grid-column: 1 / span 4;
      max-width: 100%;
      height: toRem(210);
      padding-left: 0;
      padding-right: 0;
      margin-bottom: toRem(30);
    }

    img {
      display: block;
      width: 100%;

      @include max-media(767) {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__right {
    grid-column: 9 / span 2;
    display: flex;
    flex-direction: column;

    // margin-right: toRem(45);
    @include max-media(960) {
      grid-column: 10 / span 3;
    }

    @include max-media(767) {
      grid-row: 2;
      grid-column: 3 / span 2;
      padding-right: toRem(10);
      margin-right: toRem(8);
    }

    .section {
      &__list {
        margin-bottom: toRem(45);
      }
    }

    &-link {
      margin-top: auto;
    }
  }
}

.section {
  &.column {
    .section {
      &__list {
        display: flex;
        flex-direction: column;

        a {
          margin-right: 0;
          margin-bottom: toRem(4);
        }
      }
    }
  }

  &__title {
    margin-bottom: toRem(3);
    font-size: toRem(28);
    font-weight: 500;

    @include max-media(640) {
      font-size: toRem(20);
    }
  }

  a {
    display: inline-block;
    margin-right: toRem(18);

    @include max-media(640) {
      display: block;
    }
  }
}
</style>
